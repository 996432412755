// custom typefaces
import "@fontsource/montserrat"
import "@fontsource/montserrat/600.css"
import "@fontsource/montserrat/700.css"

// normalize CSS across browsers
import "./src/css/normalize.css"

const disableCorePrefetching = () => true

export {
  disableCorePrefetching
}